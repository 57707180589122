import React from "react";
import Background from "../../assets/platKapalGambar.PNG";
import FotoHeader from "../../assets/IMG_7303.PNG";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">1. Pembuatan Kapal</h2>
              <p className="text-justify mt-2">
                Seperti namanya, plat kapal digunakan dalam pembuatan kapal
                seperti lambung kapal dan deck kapal
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Struktur Penopang
              </h2>
              <p className="text-justify mt-2">
                Plat kapal digunakan untuk membentuk struktur penyangga seperti
                bingkai dan balok, memberikan kekuatan dan kestabilan tambahan
                pada struktur kapal.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Konstruksi Dermaga
              </h2>
              <p className="text-justify mt-2">
                Plat kapal digunakan untuk konstruksi struktur dermaga. Sifatnya
                yang kuat dan tahan terhadap korosi membuat plat kapal
                seringkali dipilih sebagai bahan dasar pembuatan dari sebuah
                dermaga.
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Pembuatan Kapal
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Seperti namanya, plat kapal digunakan dalam pembuatan kapal
                seperti lambung kapal dan deck kapal
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Struktur Penopang
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat kapal digunakan untuk membentuk struktur penyangga seperti
                bingkai dan balok, memberikan kekuatan dan kestabilan tambahan
                pada struktur kapal.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Konstruksi Dermaga
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat kapal digunakan untuk konstruksi struktur dermaga. Sifatnya
                yang kuat dan tahan terhadap korosi membuat plat kapal
                seringkali dipilih sebagai bahan dasar pembuatan dari sebuah
                dermaga.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
