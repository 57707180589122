import React from "react";
import { Navbar, Footer } from "../components";
import AsstBack from "../assets/ASST41-1.jpeg";
import AsstImage from "../assets/ASST41-2.jpeg";
import BordesBack from "../assets/bordesHeader.jpg"
import BordesImage from "../assets/bordes.jpeg"

const OtherProducts = () => {
  const AsstBgHeader = {
    backgroundImage: `url(${AsstBack})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };
  const BordesBgHeader = {
    backgroundImage: `url(${BordesBack})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };
  return (
    <>
      <Navbar />
      {/* MODEL UNTUK PC */}
      <section className="container-screen hidden sm:block">
        <div className="font-helvetica text-4xl text-left text-primaryPandawa py-12">
          <h1 className="tracking-wider">OTHER PRODUCTS</h1>
        </div>

        <div>
          <div
            className=" text-left font-helvetica text-4xl rounded-lg"
            style={AsstBgHeader}
          >
            <div className="text-white py-10 px-12  bg-gradient-to-r from-black/90 via-slate-900/90">
              ASST41
            </div>
          </div>
          <div className="grid grid-cols-3 py-12">
            <div className="col-span-1 rounded-lg w-[300px] h-[300px] overflow-hidden  ">
              <img src={AsstImage} className="w-full " />
            </div>
            <div className="col-span-2 text-left pl-7 border-l-8 border-primaryPandawa py-7 font-montserrat flex flex-col items-center justify-center">
              <p className="mb-3">
                Besi as adalah material yang umumnya digunakan dalam konstruksi,
                manufaktur, dan berbagai aplikasi teknik. Ini adalah jenis baja
                karbon rendah atau sedang yang dikenal karena kekuatannya yang
                tinggi dan kemampuan untuk dibentuk dan diubah bentuknya dengan
                relatif mudah saat dipanaskan.
              </p>
              <p>
                Besi as memiliki komposisi utama yang terdiri dari besi dan
                karbon, dengan jumlah karbon biasanya kurang dari 2%. Kekuatan
                dan ketahanan besi as dapat diubah dengan proses perlakuan panas
                seperti pemanasan dan pendinginan yang tepat.
              </p>
            </div>
          </div>
        </div>

        <div>
          <div
            className=" text-left font-helvetica text-4xl rounded-lg"
            style={BordesBgHeader}
          >
            <div className="text-white py-10 px-12  bg-gradient-to-r from-black/90 via-slate-900/90">
              BORDES
            </div>
          </div>
          <div className="grid grid-cols-3 py-12">
            <div className="col-span-2 text-left pr-7 border-r-8 border-primaryPandawa py-7 font-montserrat flex flex-col items-center justify-center">
              <p className="mb-3">
                Besi bordes adalah jenis material yang umumnya terbuat dari plat
                baja atau besi yang memiliki permukaan bergelombang atau
                bertekstur. Permukaan bergelombang ini dirancang untuk
                memberikan daya cengkram yang lebih baik, membuatnya cocok untuk
                digunakan sebagai lantai atau tangga pada berbagai struktur.
              </p>
            </div>
            <div className="ml-auto col-span-1 rounded-lg w-[300px] h-[300px] overflow-hidden  ">
              <img src={BordesImage} className="w-full " />
            </div>
          </div>
        </div>
      </section>
      {/* --------------------------------- */}
      {/* MODEL UNTUK MOBILE */}
      <section className="container-screen sm:hidden">
        <div className="text-primaryPandawa py-7">
          <h1 className="text-lg font-helvetica">OTHER PRODUCTS</h1>
        </div>
        <div>
          <div>
            <div
              className=" text-center font-helvetica text-lg rounded-lg"
              style={AsstBgHeader}
            >
              <div className="text-white py-5 px-12  tracking-wider bg-gradient-to-r from-black/90 via-slate-900/90">
                ASST41
              </div>
            </div>
            <div className="text-justify text-sm py-7 font-montserrat">
              <p className="mb-3">
                Besi as adalah material yang umumnya digunakan dalam konstruksi,
                manufaktur, dan berbagai aplikasi teknik. Ini adalah jenis baja
                karbon rendah atau sedang yang dikenal karena kekuatannya yang
                tinggi dan kemampuan untuk dibentuk dan diubah bentuknya dengan
                relatif mudah saat dipanaskan.
              </p>
              <p>
                Besi as memiliki komposisi utama yang terdiri dari besi dan
                karbon, dengan jumlah karbon biasanya kurang dari 2%. Kekuatan
                dan ketahanan besi as dapat diubah dengan proses perlakuan panas
                seperti pemanasan dan pendinginan yang tepat.
              </p>
            </div>
          </div>
          <div>
            <div
              className=" text-center font-helvetica text-lg rounded-lg"
              style={BordesBgHeader}
            >
              <div className="text-white py-5 px-12  tracking-wider bg-gradient-to-r from-black/90 via-slate-900/90">
                BORDES
              </div>
            </div>
            <div className="text-justify text-sm py-7 font-montserrat">
              <p className="mb-3">
                Besi bordes adalah jenis material yang umumnya terbuat dari plat
                baja atau besi yang memiliki permukaan bergelombang atau
                bertekstur. Permukaan bergelombang ini dirancang untuk
                memberikan daya cengkram yang lebih baik, membuatnya cocok untuk
                digunakan sebagai lantai atau tangga pada berbagai struktur.
              </p>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default OtherProducts;
