import React from "react";
import { Navbar, Footer } from "../components";
import FotoHeader from "../assets/2FotoPabrik.jpeg";
import { Coil, Plat } from "../components/ProductPage.jsx";
import { Link } from "react-router-dom"

const Product = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };
  return (
    <>
      <Navbar />
      <section style={BgHeader}>
        <div className="bg-black/80 py-20">
          <div className="container-screen grid grid-cols-3">
            <h1 className="font-helvetica text-white col-span-3 sm:col-span-2 text-left text-2xl sm:text-4xl leading-relaxed sm:leading-normal">
              PT Pandawa Jaya Steel menyediakan berbagai macam jenis{" "}
              <span className="text-primaryPandawa">coil</span> dan{" "}
              <span className="text-primaryPandawa">plat besi</span> sesuai
              dengan kebutuhan industri .
            </h1>
          </div>
        </div>
      </section>
      <section>
        <Coil />
      </section>
      <section>
        <Plat />
      </section>
      <section className="py-4 sm:py-10">
        <Link to="/other-products">
          <button className="container-screen flex items-center justify-end hover:opacity-70 transition duration-300">
            <h2 className="font-montserrat font-[700] sm:text-2xl">
              OTHER PRODUCTS
            </h2>
            <div className="h-7 w-7 sm:h-10 sm:w-10 bg-primaryPandawa border-2 border-slate-800 rounded-full ml-3 flex items-center">
              <p className="font-montserrat font-[700] mx-auto ml-auto sm:text-2xl">
                {">"}
              </p>
            </div>
          </button>
        </Link>
      </section>
      <Footer />
    </>
  );
};

export default Product;
