import React from "react";
import PlatHitam from "../../assets/plat_putih2.jpeg";
import PlatHitamPhone from "../../assets/platPutihPhone.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={PlatHitam} className="h-full w-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            PLAT PUTIH
          </h1>
          <h2 className="text-xl font-helvetica text-primaryPandawa text-left mt-2 pl-1">
            {"[ Cold Rolled Sheet ]"}
          </h2>
          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Plat putih adalah bahan konstruksi yang penting dan banyak digunakan
            di berbagai sektor industri karena kehalusan permukaan dan ketepatan
            ukurannya. Hal ini memungkinkan penggunaan dalam aplikasi yang
            memerlukan presisi tinggi.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={PlatHitamPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            PLAT PUTIH
          </h1>
          <h2 className="font-montserrat font-[700] text-sm text-primaryPandawa">
            {"[ Cold Rolled Sheet ]"}
          </h2>
          <p className="font-montserrat text-justify mt-4">
            Plat putih adalah bahan konstruksi yang penting dan banyak digunakan
            di berbagai sektor industri karena kehalusan permukaan dan ketepatan
            ukurannya. Hal ini memungkinkan penggunaan dalam aplikasi yang
            memerlukan presisi tinggi.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
