import React from "react";
import Background from "../../assets/coilAbuBackground.jpg";
import FotoHeader from "../../assets/coilAbuProducts.jpeg";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">
                1. Industri Konstruksi
              </h2>
              <p className="text-justify mt-2">
                Coil abu-abu dapat digunakan untuk struktur bangunan, baik
                sebagai bahan struktural utama atau sebagai komponen tambahan.
                Sifat kekuatan dan ketahanannya terhadap beban struktural
                membuatnya cocok untuk digunakan dalam elemen-elemen konstruksi.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Pembuatan Alat Berat
              </h2>
              <p className="text-justify mt-2">
                Coil abu-abu merupakan material yang seringkali digunakan dalma
                pembuatan alat berat. Digunakan karena strukturnya yang kuat,
                tahan terhadap korosi
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Industri Otomotif
              </h2>
              <p className="text-justify mt-2">
                Dalam pembuatan kendaraan, coil abu-abu dapat digunakan untuk
                berbagai komponen, termasuk rangka kendaraan, panel bodi, atau
                bagian struktural lainnya. Keandalannya dalam memberikan
                kekuatan struktural dapat menjadi nilai tambah dalam aplikasi
                otomotif.
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Industri Konstruksi
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil abu-abu dapat digunakan untuk struktur bangunan, baik
                sebagai bahan struktural utama atau sebagai komponen tambahan.
                Sifat kekuatan dan ketahanannya terhadap beban struktural
                membuatnya cocok untuk digunakan dalam elemen-elemen konstruksi.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Pembuatan Alat Berat
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil abu-abu merupakan material yang seringkali digunakan dalma
                pembuatan alat berat. Digunakan karena strukturnya yang kuat,
                tahan terhadap korosi
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Industri Otomotif
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Dalam pembuatan kendaraan, coil abu-abu dapat digunakan untuk
                berbagai komponen, termasuk rangka kendaraan, panel bodi, atau
                bagian struktural lainnya. Keandalannya dalam memberikan
                kekuatan struktural dapat menjadi nilai tambah dalam aplikasi
                otomotif.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
