import React from "react";
import PlatKapal from "../../assets/IMG_7303.PNG";
import PlatHitamPhone from "../../assets/platKapalPhone.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={PlatKapal} className="h-full w-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            PLAT KAPAL
          </h1>

          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Plat kapal adalah jenis baja yang khusus dirancang dan diproduksi
            untuk keperluan pembuatan kapal, struktur maritim dan lainnya.
            Mereka memiliki karakteristik tertentu yang membuatnya cocok untuk
            penggunaan di lingkungan laut yang keras dan lainnya.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={PlatHitamPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            PLAT KAPAL
          </h1>

          <p className="font-montserrat text-justify mt-4">
            Plat kapal adalah jenis baja yang khusus dirancang dan diproduksi
            untuk keperluan pembuatan kapal, struktur maritim dan lainnya.
            Mereka memiliki karakteristik tertentu yang membuatnya cocok untuk
            penggunaan di lingkungan laut yang keras dan lainnya.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
