import React from "react";
import { NavbarOther, FooterOther } from "../components";
import FotoHeader from "../assets/Proses_pembukaan_coil.jpg";
import Pipa from "../assets/Pipa.jpg"
import Hollow from "../assets/Hollow.jpeg"

const OtherCompany = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };
  return (
    <>
      <NavbarOther />
      <section>
        <section style={BgHeader}>
          <div className="bg-black/80 py-20">
            <div className="container-screen grid grid-cols-3">
              <h1 className="font-helvetica text-white col-span-3 text-center sm:text-left text-xl sm:text-3xl leading-relaxed sm:leading-normal">
                PT Pandawa Jaya Sejahtera berdiri sejak tahun 2011 sebagai
                penyedia solusi <span className="text-primaryPandawa">pipa</span> dan <span className="text-primaryPandawa">hollow</span> berkualitas tinggi untuk
                kebutuhan konstruksi dan industri, yang merupakan salah satu
                anak perusahaan dari PT Pandawa Jaya Steel
              </h1>
            </div>
          </div>
        </section>
        <section className="py-12 container-screen">
          <div className="font-helvetica text-primaryPandawa text-xl sm:text-4xl text-center sm:text-left mb-8">
            <h1>PRODUCTS</h1>
          </div>
          <div className="sm:grid sm:grid-cols-2">
            <div className="flex mb-7">
              <div className="h-[150px] w-[150px] sm:h-[250px] sm:w-[250px] rounded-lg overflow-hidden">
                <img src={Pipa} className="h-full w-full"/>
              </div>
              <div className="text-left ml-5 flex flex-col justify-center">
                <h1 className="font-helvetica text-xl mb-4">PIPA</h1>
                <h2 className="font-helvetica text-sm sm:text-lg">Type:</h2>
                <p className="font-montserrat text-xs sm:text-sm">Putih, Hitam, & Galvanis</p>
                <h2 className="font-helvetica mt-4 text-sm sm:text-lg">Size:</h2>
                <p className="font-montserrat text-xs sm:text-sm">Standard & Custom Size</p>
              </div>
            </div>
            <div className="flex">
              <div className="flex mb-7">

              <div className="h-[150px] w-[150px] sm:h-[250px] sm:w-[250px] rounded-lg overflow-hidden">
                <img src={Hollow} className="h-full w-full"/>
              </div>
              <div className="text-left ml-5 flex flex-col justify-center">
                <h1 className="font-helvetica text-xl mb-4">HOLLOW</h1>
                <h2 className="font-helvetica text-sm sm:text-lg">Type:</h2>
                <p className="font-montserrat text-xs sm:text-sm">Putih, Hitam, & Galvanis</p>
                <h2 className="font-helvetica mt-4 text-sm sm:text-lg">Size:</h2>
                <p className="font-montserrat text-xs sm:text-sm">Standard & Custom Size</p>
              </div>
              </div>
            </div>
          </div>
        </section>
      </section>
      <FooterOther />
    </>
  );
};

export default OtherCompany;
