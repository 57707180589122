import React from "react";
import CoilHitam from "../../assets/coilHitamFull.jpg";
import CoilPutih from "../../assets/CoilPutihProduct.jpeg";
import CoilAbu from "../../assets/coilAbuProducts.jpeg";
import CoilGalvanis from "../../assets/coilGalvanisProducts.jpeg";
import CoilGalvanil from "../../assets/coil_galvanil2.jpg"
import { Link } from "react-router-dom";

const Coil = () => {
  return (
    <>
      <section className="container-screen pt-8 sm:pt-12 py-5">
        <h1 className="text-left text-4xl tracking-wider text-primaryPandawa  font-helvetica font-extrabold ">
          COIL
        </h1>
      </section>
      <section className="bg-primaryPandawa py-4 sm:py-9">
        <div className="container-screen">
          <div className="grid grid-cols-3  gap-3 sm:gap-1 place-items-start justify-items-center">
            <div>
              <Link to="/coil-hitam">
                <div className="overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48 bg-white">
                  <img
                    src={CoilHitam}
                    className="h-full hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
              </Link>
              <p className="font-montserrat text-white text-xs sm:text-lg mt-2">
                Coil Hitam
              </p>
            </div>
            <Link to="/coil-putih">
              <div>
                <div className="bg-white overflow-hidden rounded-lg h-16 w-16 sm:w-48 sm:h-48">
                  <img
                    src={CoilPutih}
                    className="h-full hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-xs sm:text-lg mt-2">
                  Coil Putih
                </p>
              </div>
            </Link>
            <Link to="/coil-abu">
              <div>
                <div className="bg-white overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48">
                  <img
                    src={CoilAbu}
                    className="w-full h-full hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-[10px] sm:text-lg mt-2">
                  Coil Abu - Abu
                </p>
              </div>
            </Link>
            </div>
            <div className="grid grid-cols-2  gap-3 sm:gap-1 place-items-start justify-items-center mt-7">
            <Link to="/coil-galvanis">
              <div>
                <div className=" bg-white overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48">
                  <img
                    src={CoilGalvanis}
                    className="hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-[10px] sm:text-lg mt-2">
                  Coil Galvanis
                </p>
              </div>
            </Link>
            <Link to="/coil-galvanil">
              <div>
                <div className=" bg-white overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48">
                  <img
                    src={CoilGalvanil}
                    className="scale-110 hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-[10px] sm:text-lg mt-2">
                  Coil Galvanil
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Coil;
