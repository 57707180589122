import React from "react";
import Background from "../../assets/plat_putih3.jpeg";
import FotoHeader from "../../assets/plat_putih2.jpeg";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">
                1. Industri Konstruksi
              </h2>
              <p className="text-justify mt-2">
                Plat putih terkadang digunakan di dalam industri konstruksi
                untuk memberikan penampilan yang bersih. Plat putih seringkali
                digunakan dalam pembuatan jendela, pintu, aksesoris eksterior,
                dan hal-hal lain yang tidak berkaitan dengan struktural.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Industri Otomotif
              </h2>
              <p className="text-justify mt-2">
                Plat putih dapat digunakan dalam pembuatan beberapa komponen
                interior mobil, seperti frame kursi atau panel interior, dapat
                menggunakan plat besi putih karena kemampuannya untuk memberikan
                tampilan yang lebih baik.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Industri Farmasi
              </h2>
              <p className="text-justify mt-2">
                Plat putih sering digunakan dalam industri farmasi, plat putih
                dapat digunakan untuk membuat peralatan laboratorium atau
                peralatan lainnya yang membutuhkan kebersihan dan ketahanan
                korosi.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                4. Pembuatan Peralatan Rumah Tangga
              </h2>
              <p className="text-justify mt-2">
                Plat putih dapat digunakan dalam pembuatan berbagai peralatan
                rumah tangga seperti kulkas, mesin cuci, oven, dan peralatan
                lainnya yang memerlukan permukaan yang bersih dan tahan korosi.
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Industri Konstruksi
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat putih terkadang digunakan di dalam industri konstruksi
                untuk memberikan penampilan yang bersih. Plat putih seringkali
                digunakan dalam pembuatan jendela, pintu, aksesoris eksterior,
                dan hal-hal lain yang tidak berkaitan dengan struktural.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Industri Otomotif
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat putih dapat digunakan dalam pembuatan beberapa komponen
                interior mobil, seperti frame kursi atau panel interior, dapat
                menggunakan plat besi putih karena kemampuannya untuk memberikan
                tampilan yang lebih baik.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Industri Farmasi
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat putih sering digunakan dalam industri farmasi, plat putih
                dapat digunakan untuk membuat peralatan laboratorium atau
                peralatan lainnya yang membutuhkan kebersihan dan ketahanan
                korosi.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                4. Pembuatan Peralatan Rumah Tangga
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat hitam digunakan dalam konstruksi bangunan sebagai bahan
                Plat putih dapat digunakan dalam pembuatan berbagai peralatan
                rumah tangga seperti kulkas, mesin cuci, oven, dan peralatan
                lainnya yang memerlukan permukaan yang bersih dan tahan korosi.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
