import logo from "./logo.svg";
import "./App.css";
import { Route, Routes, useLocation } from "react-router-dom";
import {
  Home,
  Product,
  CoilHitam,
  CoilPutih,
  CoilAbu,
  CoilGalvanis,
  CoilGalvanil,
  PlatHitam,
  PlatPutih,
  PlatAbu,
  PlatKapal,
  HubungiKami,
  AboutUs,
  OtherProducts,
  OtherCompany
} from "./page";
import { WhatsappLink } from "./components"

function App() {
  const location = useLocation();

  let whatsappLink = "https://linktr.ee/pandawajayasteel.id";
  if (location.pathname === "/other-company") {
    whatsappLink = "https://linktr.ee/pandawajayasejahtera";
  }

  return (
    <div className="App">
      <WhatsappLink link={whatsappLink} />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/product" element={<Product />} />
        <Route path="/coil-hitam" element={<CoilHitam />} />
        <Route path="coil-putih" element={<CoilPutih />} />
        <Route path="/coil-abu" element={<CoilAbu />} />
        <Route path="/coil-galvanis" element={<CoilGalvanis />} />
        <Route path="/coil-galvanil" element={<CoilGalvanil />} />
        <Route path="/plat-hitam" element={<PlatHitam />} />
        <Route path="/plat-putih" element={<PlatPutih />} />
        <Route path="/plat-abu" element={<PlatAbu />} />
        <Route path="/plat-kapal" element={<PlatKapal />} />
        <Route path="/hubungi-kami" element={<HubungiKami />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/other-products" element={<OtherProducts />} />
        <Route path="/other-company" element={<OtherCompany />} />
      </Routes>
    </div>
  );
}

export default App;

