import React from "react";
import CoilAbu from "../../assets/coilAbuProducts.jpeg";
import CoilAbuPhone from "../../assets/coilAbuPhone.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={CoilAbu} className="h-full w-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            COIL ABU - ABU
          </h1>
          <h2 className="text-xl font-helvetica text-primaryPandawa text-left mt-2 pl-1">
            {"[ Hot Rolled Coil ]"}
          </h2>
          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Coil abu-abu adalah lembaran baja yang telah melalui dua proses
            utama yaitu hot rolling dan pickling serta oiling.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={CoilAbuPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            COIL ABU- ABU
          </h1>
          <h2 className="font-montserrat font-[700] text-sm text-primaryPandawa">
            {"[ Hot Rolled Coil ]"}
          </h2>
          <p className="font-montserrat text-justify mt-4">
            Coil abu-abu adalah lembaran baja yang telah melalui dua proses
            utama yaitu hot rolling dan pickling serta oiling.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
