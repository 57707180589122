import React from "react";
import Logo from "../assets/pandawa_logo.png";
import { Link } from "react-router-dom";

const NavbarOther = () => {
  return (
    <div className="h-10 sm:h-16 bg-primaryPandawa flex items-center justify-center py-10">
      <section className="flex container-screen">
        <Link to="/">
          <div className="flex mr-auto items-center">
            <div className="sm:h-10 sm:w-10 h-7 w-7">
              <img src={Logo} />
            </div>
            <div>
              <p className="ml-3 sm:text-xl font-helios text-xs text-white">
                PT Pandawa Jaya Sejahtera
              </p>
              <p className="font-montserrat text-xs text-left ml-3 text-white">
                Part of PT Pandawa Jaya Steel
              </p>
            </div>
          </div>
        </Link>
      </section>
    </div>
  );
};

export default NavbarOther;
