import React from "react";
import PlatAbu from "../../assets/plat_abu1.jpeg";
import PlatAbuPhone from "../../assets/platAbuPhone.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={PlatAbu} className="h-full w-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            PLAT ABU - ABU
          </h1>
          <h2 className="text-xl font-helvetica text-primaryPandawa text-left mt-2 pl-1">
            {"[ Hot Rolled Pickled Oil Plate ]"}
          </h2>
          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Plat abu-abu adalah bahan konstruksi penting yang digunakan dalam
            berbagai sektor industri karena kekuatan dan daya tahannya. Dengan
            perlindungan tambahan dari lapisan pelindung, mereka cocok untuk
            digunakan dalam lingkungan yang memerlukan ketahanan ekstra terhadap
            korosi.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={PlatAbuPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            PLAT ABU - ABU
          </h1>
          <h2 className="font-montserrat font-[700] text-sm text-primaryPandawa">
            {"[ Hot Rolled Pickled Oil Plate ]"}
          </h2>
          <p className="font-montserrat text-justify mt-4">
            Plat abu-abu adalah bahan konstruksi penting yang digunakan dalam
            berbagai sektor industri karena kekuatan dan daya tahannya. Dengan
            perlindungan tambahan dari lapisan pelindung, mereka cocok untuk
            digunakan dalam lingkungan yang memerlukan ketahanan ekstra terhadap
            korosi.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
