import React from "react";
import Background from "../../assets/BackgroundCoilHitam.jpg";
import FotoHeader from "../../assets/coilHitamFull.jpg"

const Aplikasi = () => {
    const BgHeader = {
        backgroundImage: `url(${FotoHeader})`,
        backgroundSize: "cover",
        backgroundPosition: "40% 60%",
        overflow: "hidden",
      };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">1. Industri Otomotif</h2>
              <p className="text-justify mt-2">
                Coil hitam digunakan dalam industri otomotif untuk pembuatan
                berbagai komponen, seperti rangka kendaraan, bagian struktural,
                dan komponen lainnya. Keunggulan coil hitam termasuk kekuatan
                yang baik dan kemampuan untuk dibentuk ke berbagai bentuk yang
                diperlukan dalam konstruksi kendaraan.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Konstruksi Bangunan
              </h2>
              <p className="text-justify mt-2">
                Baja coil hitam juga digunakan dalam industri konstruksi untuk
                membangun bangunan, struktur, dan infrastruktur. Ini digunakan
                dalam pembuatan rangka bangunan, tiang, dan komponen struktural
                lainnya karena kekuatan dan daya tahan yang baik.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Peralatan Rumah Tangga
              </h2>
              <p className="text-justify mt-2">
                Coil hitam digunakan dalam pembuatan berbagai peralatan rumah
                tangga seperti oven, lemari es, mesin cuci, kompor, dan banyak
                lagi. Baja hitam sering digunakan untuk bagian-bagian yang
                membutuhkan kekuatan dan daya tahan, serta daya hantam yang
                baik.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                4. Industri Manufaktur
              </h2>
              <p className="text-justify mt-2">
                Banyak industri manufaktur menggunakan coil hitam untuk
                memproduksi berbagai jenis komponen, termasuk barang konsumen
                dan mesin industri. Keunggulan baja hitam dalam kemampuan
                pengolahan dan kekuatan membuatnya populer di berbagai aplikasi
                manufaktur.
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Industri Otomotif
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil hitam digunakan dalam industri otomotif untuk pembuatan
                berbagai komponen, seperti rangka kendaraan, bagian struktural,
                dan komponen lainnya. Keunggulan coil hitam termasuk kekuatan
                yang baik dan kemampuan untuk dibentuk ke berbagai bentuk yang
                diperlukan dalam konstruksi kendaraan.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Konstruksi Bangunan
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Baja coil hitam juga digunakan dalam industri konstruksi untuk
                membangun bangunan, struktur, dan infrastruktur. Ini digunakan
                dalam pembuatan rangka bangunan, tiang, dan komponen struktural
                lainnya karena kekuatan dan daya tahan yang baik.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Peralatan Rumah Tangga
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil hitam digunakan dalam pembuatan berbagai peralatan rumah
                tangga seperti oven, lemari es, mesin cuci, kompor, dan banyak
                lagi. Baja hitam sering digunakan untuk bagian-bagian yang
                membutuhkan kekuatan dan daya tahan, serta daya hantam yang
                baik.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                4. Industri Manufaktur
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Banyak industri manufaktur menggunakan coil hitam untuk
                memproduksi berbagai jenis komponen, termasuk barang konsumen
                dan mesin industri. Keunggulan baja hitam dalam kemampuan
                pengolahan dan kekuatan membuatnya populer di berbagai aplikasi
                manufaktur.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
