import React from "react";
import CoilGalvanis from "../../assets/coilGalvanisProducts.jpeg";
import CoilGalvanisPhone from "../../assets/coilGalvanisPhone.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={CoilGalvanis} className="h-full w-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            COIL GALVANIS
          </h1>

          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Coil Galavanis adalah gulungan baja yang telah melalui proses
            pelapisan dengan lapisan seng ( zinc ) untuk mencegah korosi.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={CoilGalvanisPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            COIL GALVANIS
          </h1>

          <p className="font-montserrat text-justify mt-4">
            Coil Galavanis adalah gulungan baja yang telah melalui proses
            pelapisan dengan lapisan seng ( zinc ) untuk mencegah korosi.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
