import React from "react";
import Logo from "../assets/pandawa_logo.png";
import { Link } from "react-router-dom";
import Backdrop from "../assets/steel rols.jpg";
import { PhoneIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import Instagram from "../assets/ig.png";
import LinkedIn from "../assets/linkedin.png";
import WhatsApp from "../assets/logo_wa.png";

const Footer = () => {
  const platStyle = {
    backgroundImage: `url(./assets/steel rols.jpg)`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };

  return (
    <>
      <Link to="/other-company">
        <section className="bg-primaryPandawa py-2 drop-shadow-2xl">
          <div className="container-screen">
            <div className="grid grid-cols-5 content-center">
              <div className="col-span-3 flex items-center">
                <img src={Logo} className="sm:h-7 sm:w-7 w-4 h-4" />
                <p className="font-helios tracking-widest ml-1 text-white text-[8px] sm:text-sm ">
                  PT Pandawa Jaya Sejahtera
                </p>
              </div>
              <div className="col-span-2 flex items-center justify-center">
                <p className="font-montserrat text-white text-[4px] sm:text-xs">
                  Part of PT Pandawa Jaya Steel | memproduksi pipa dan hollow
                </p>
              </div>
            </div>
          </div>
        </section>
      </Link>
      <section className="block" style={platStyle}>
        <section className="bg-black/90 py-2">
          <div className="container-screen">
            <h1 className="text-start font-helvetica text-white text-xs sm:text-xl mb-2 sm:mb-4">
              OUR CONTACT
            </h1>
            <div className="grid grid-cols-4">
              <div className="col-span-2 sm:col-span-3 justify-items-start">
                <div className="flex items-center">
                  <EnvelopeIcon className="sm:h-5 sm:w-5 h-3 w-3 text-white my-1" />
                  <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                    Pjs@pandawajayasteel.co.id
                  </p>
                </div>
                <div className="flex items-center sm:my-1">
                  <PhoneIcon className="sm:h-5 sm:w-5 h-3 w3 text-white my-1" />
                  <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                    (021) 5437 5641 / (021) 5437 5252
                  </p>
                </div>
                <div className="flex items-center sm:my-1">
                  <img
                    src={WhatsApp}
                    className="sm:h-5 sm:w-5 h-3 w3 text-white my-1"
                  />
                  <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                    +62 813-9933-0096 / +62 813-9933-0095
                  </p>
                </div>
              </div>
              <div className="sm:col-span-1 col-span-2">
                <Link to="https://www.linkedin.com/company/ptpandawajayasteel/">
                  <div className="flex items-center sm:my-1">
                    <img
                      src={LinkedIn}
                      className="sm:h-5 sm:w-5 h-3 w3 text-white my-1 ml-auto sm:ml-0"
                    />
                    <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                      PT. Pandawa Jaya Steel
                    </p>
                  </div>
                </Link>
                <Link to="https://www.instagram.com/pt.pandawajayasteel/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                  <div className="flex items-center sm:my-1">
                    <img
                      src={Instagram}
                      className="sm:h-5 sm:w-5 h-3 w3 text-white my-1 ml-auto sm:ml-0"
                    />
                    <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                      @pt.pandawajayasteel
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default Footer;
