import React from "react";
import WaImage from "../assets/WhatsApp_icon.png";
import { Link } from "react-router-dom";

const WhatsappLink = ({link}) => {
  return (
    <div className="fixed right-5 bottom-5 z-50">
      <button>
        <Link to={link}>
          <img
            className="w-14 h-14 lg:w-16 lg:h-16"
            src={WaImage}
            alt="Wa_icon.png"
          />
        </Link>
      </button>
    </div>
  );
};

export default WhatsappLink;
