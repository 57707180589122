import React from "react";
import { Footer, Navbar } from "../components";
import { Header, Aplikasi } from "../components/PlatAbu";

const PlatAbu = () => {
  return (
    <>
      <Navbar />
      <section>
        <Header />
      </section>
      <section className="container-screen">
        <h1 className="font-helvetica sm:text-4xl text-2xl sm:text-left text-center text-primaryPandawa">
          Ukuran Umum
        </h1>
        <h1 className="font-helvetica sm:text-left text-center sm:text-3xl text-2xl my-3">
          4'X 8'
        </h1>
        <h3 className="font-montserrat text-slate-400 text-xs sm:text-left text-center">
          *Available for custom size
        </h3>
      </section>
      <section className="pt-10">
        <Aplikasi />
      </section>
      <Footer />
    </>
  );
};

export default PlatAbu;
