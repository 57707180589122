import React from "react";
import { Footer, Navbar } from "../components";
import Background from "../assets/mesin.jpg";

const AboutUs = () => {
  const BgHeader = {
    backgroundImage: `url(${Background})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };
  return (
    <>
      <Navbar />
      <section style={BgHeader}>
        <div className="bg-black/70 sm:py-12 pt-12">
          <div className="container-screen text-white text-left">
            <h1 className="font-helvetica text-xl sm:text-4xl tracking-wider">
              ABOUT US
            </h1>
            <p className="font-montserrat mt-4 sm:text-left text-justify">
              PT Pandawa Jaya Steel adalah perusahaan yang berdiri sejak tahun
              1999 di Indonesia. PT Pandawa Jaya Steel hadir di Indonesia
              sebagai perusahaan besi yang datang dengan penuh keyakinan dan
              kepercayaan diri agar bisa masuk ke dalam perdagangan besi di
              Indonesia.
            </p>
          </div>
          {/* BAWAH UNTUK PC */}
          <div className="sm:grid grid-cols-5 hidden ">
            <div className="col-span-3 rounded-tr-lg rounded-br-lg mt-12  grid grid-cols-6 bg-primaryPandawa py-8">
              <div className="col-span-1"></div>
              <div className="col-span-5 text-white text-left">
                <h1 className="font-helvetica tracking-widest text-4xl">
                  VISI
                </h1>
                <p className="mr-12 font-montserrat mt-4">
                  Menjadi perusahaan perdagangan baja nasional yang terpercaya
                  di Indonesia dan menjamin kualitas, menciptakan kepuasan
                  pelanggan serta berkontribusi pada masyarakat Indonesia.
                </p>
                <h1 className="font-helvetica tracking-widest text-4xl mt-7">
                  MISI
                </h1>
                <p className="mr-12 font-montserrat mt-4">
                  1. Menjadi stockist dan distributor baja yg profesional dan
                  dapat dipercaya.
                </p>
                <p className="mr-12 font-montserrat mt-4">
                  2. Menjadi perusahaan yang fokus dan mampu bersaing secara
                  global .
                </p>
                <p className="mr-12 font-montserrat mt-4 flex">
                  <p className="mr-1">3.</p>
                  Berkomitmen memberikan kepuasan pelanggan dengan menjaga
                  kualitas, Harga dan Waktu pengiriman.
                </p>
                <p className="mr-12 font-montserrat mt-4 flex">
                  <p className="mr-1">4.</p>
                  Meningkatkan produktivitas dan kualitas kerja SDM dengan
                  pelatihan dan pengembangan kompetensi.
                </p>
              </div>
            </div>
          </div>
          {/* ----------------- */}
          {/* BAWAH UNTUK MOBILE */}
          <div className="bg-primaryPandawa container-screen py-6 sm:hidden px-6 rounded-tl-lg rounded-tr-lg mt-12">
            <div className="text-white text-sm">
              <h1 className="font-helvetica text-2xl">VISI</h1>
              <p className="font-montserrat text-justify">
                Menjadi perusahaan perdagangan baja nasional yang terpercaya di
                Indonesia dan menjamin kualitas, menciptakan kepuasan pelanggan
                serta berkontribusi pada masyarakat Indonesia.
              </p>
            </div>
            <div className="text-white text-sm">
              <h1 className="font-helvetica text-2xl mt-12">MISI</h1>
              <div className="font-montserrat text-justify flex my-4">
                <p className="mr-1">1.</p>

                <p>
                  Menjadi stockist dan distributor baja yg profesional dan dapat
                  dipercaya.
                </p>
              </div>
              <div className="font-montserrat text-justify flex my-4">
                <p className="mr-1">2.</p>

                <p>
                  Menjadi perusahaan yang fokus dan mampu bersaing secara
                  global.{" "}
                </p>
              </div>
              <div className="font-montserrat text-justify flex my-4">
                <p className="mr-1">3.</p>

                <p>
                  Berkomitmen memberikan kepuasan pelanggan dengan menjaga
                  kualitas, Harga dan Waktu pengiriman.
                </p>
              </div>
              <div className="font-montserrat text-justify flex my-4">
                <p className="mr-1">4.</p>

                <p>
                  Meningkatkan produktivitas dan kualitas kerja SDM dengan
                  pelatihan dan pengembangan kompetensi.{" "}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default AboutUs;
