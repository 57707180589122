import React from "react";
import { Navbar, Footer } from "../components";
import { Header, Aplikasi } from "../components/CoilAbuPage.jsx"

const CoilAbu = () => {
  return (
    <>
      <Navbar />
      <section>
        <Header />
      </section>
      <section className="pt-10">
        <Aplikasi />
      </section>
      <Footer />
    </>
  );
};

export default CoilAbu;
