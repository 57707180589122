import React from "react";
import { Navbar, Footer } from "../components";
import BgPlat from "../assets/steelManufacturing.PNG";

const HubungiKami = () => {
  const platStyle = {
    backgroundImage: `url(${BgPlat})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  };
  return (
    <>
      <Navbar />
      <section className="hidden sm:block" style={platStyle}>
        {/* HUBUNGI KAMI PC */}
        <div className="bg-white/90">
          <div className="container-screen">
            <div className="py-12">
              <h1 className="font-helvetica text-black text-xl sm:text-4xl text-center sm:text-left">
                {" "}
                HUBUNGI KAMI
              </h1>
              <div className="grid sm:grid-cols-4 grid-cols-2 gap-5 mt-12">
                <div className="text-left ">
                  <h2 className="font-helvetica">Kantor Jakarta</h2>
                  <p className="font-montserrat">
                    Jl Kapuk Kamal Muara V No.52 Jakarta Utara, 14470
                  </p>
                </div>
                <div className="text-left ">
                  <h2 className="font-helvetica">Telpon</h2>
                  <p className="font-montserrat">(021) 5437-5641</p>
                  <p className="font-montserrat">(021) 5437-5252</p>
                </div>
                <div className="text-left ">
                  <h2 className="font-helvetica">Whatsapp</h2>
                  <p className="font-montserrat">+62 813-9933-0096</p>
                  <p className="font-montserrat">+62 813-9933-0095</p>
                </div>
                <div className="text-left ">
                  <h2 className="font-helvetica">Email</h2>
                  <p className="font-montserrat">PJS@PandawaJayaSteel.co.id</p>
                </div>
              </div>
            </div>
            <div className="bg-primaryPandawa px-12 py-10">
              <h1 className="text-4xl font-helvetica text-left text-white tracking-wider">
                LOKASI KAMI
              </h1>
              <div className="flex">
                <div className="text-left font-helvetica text-white mt-12 mr-28">
                  <h1 className="text-xl font-helvetica underline-offset-4 underline ">
                    JAKARTA
                  </h1>
                  <div className="my-5">
                    <h2>Kantor</h2>
                    <p className="font-montserrat">
                      Jl Kapuk Kamal Muara V No.52 <br />
                      Jakarta Utara, 14470
                    </p>
                  </div>
                  <div className="mt-5 mb-12">
                    <h2>Gudang</h2>
                    <p className="font-montserrat">
                      Jl Kapuk Kamal Muara V No.52 <br />
                      Jakarta Utara, 14470
                    </p>
                  </div>
                </div>
                <div className="text-left font-helvetica text-white mt-12">
                  <h1 className="text-xl font-helvetica underline-offset-4 underline ">
                    TANGERANG
                  </h1>

                  <div className="mt-5 mb-12">
                    <h2>Gudang</h2>
                    <p className="font-montserrat">Cikupa, Tangerang</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* HUBUNGI KAMI PHONE */}
      <section className="block sm:hidden" style={platStyle}>
        <div className="bg-white/90  block sm:hidden">
          <div className="container-screen">
            <div className="font-helvetica py-12">
              <h1 className="text-2xl">HUBUNGI KAMI</h1>
              <div className="grid grid-cols-2 gap-4 mt-10">
                <div className="text-left">
                  <h2>Kantor Jakarta</h2>
                  <p className="font-montserrat text-xs">
                    Jl Kapuk Kamal Muara V No.52 Jakarta Utara, 14470
                  </p>
                </div>
                <div className="text-left">
                  <h2>Email</h2>
                  <p className="font-montserrat text-xs">
                    PJS@PandawaJayaSteel.co.id
                  </p>
                </div>
                <div className="text-left">
                  <h2>Whatsapp</h2>
                  <p className="font-montserrat text-xs">+62 813-9933-0096</p>
                  <p className="font-montserrat text-xs">+62 813-9933-0095</p>
                </div>
                <div className="text-left">
                  <h2>Telpon</h2>
                  <p className="font-montserrat text-xs">(021) 5437-5641</p>
                  <p className="font-montserrat text-xs">(021) 5437-5252</p>
                </div>
              </div>
            </div>
            <div className="bg-primaryPandawa text-white font-helvetica pt-5 pb-12 rounded-tr-lg rounded-tl-lg">
              <h1 className="font-helvetica text-2xl mb-6"> LOKASI KAMI</h1>
              <div>
                <h1 className="underline underline-offset-4 mb-3">JAKARTA</h1>
                <div className="my-4">
                  <h2 className="text-base">Kantor</h2>
                  <p className="font-montserrat text-sm">
                    Jl Kapuk Kamal Muara V No.52 <br />
                    Jakarta Utara, 14470
                  </p>
                </div>
                <div className="my-4">
                  <h2 className="text-base">Gudang</h2>
                  <p className="font-montserrat text-sm">
                    Jl Kapuk Kamal Muara V No.52 <br />
                    Jakarta Utara, 14470
                  </p>
                </div>
              </div>
              <div className="mt-12 mb-3">
                <h1 className="underline underline-offset-4">TANGERANG</h1>

                <div className="mt-4">
                  <h2 className="text-base">Gudang</h2>
                  <p className="font-montserrat text-sm">Cikupa, Tangerang</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
};

export default HubungiKami;
