import React, { useState, Fragment } from "react";
import Logo from "../assets/pandawa_logo.png";
import { Link } from "react-router-dom";
import { Menu, Transition } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";

const Navbar = () => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="h-10 sm:h-16 bg-primaryPandawa flex items-center justify-center py-10">
        <section className="flex container-screen">
          <div className="flex mr-auto items-center">
            <div className="sm:h-10 sm:w-10 h-7 w-7">
              <img src={Logo} />
            </div>
            <p className="ml-3 sm:text-xl font-helios text-xs text-white">
              PT Pandawa Jaya Steel
            </p>
          </div>
          <div className="sm:flex items-center hidden">
            <Link to="/">
              <p className="header-font">Home</p>
            </Link>
            <Link to="/about-us">
              <p className="header-font">About Us</p>
            </Link>
            <Link to="/product">
            <button className="header-font">Products</button>
            </Link>

            <Link to="/hubungi-kami">
              <p className="header-font">Contact</p>
            </Link>
          </div>
          <div>
            {open ? (
              <XMarkIcon
                className="relative w-7 h-7 sm:hidden text-white cursor-pointer transition "
                onClick={() => setOpen(!open)}
              />
            ) : (
              <Bars3Icon
                className="relative w-7 h-7 sm:hidden text-white cursor-pointer transition "
                onClick={() => setOpen(!open)}
              />
            )}

            {open && (
              <div className="absolute right-10 top-20 rounded-md sm:hidden  w-36 bg-white text-primaryPandawa py-2">
                <Link to="/" onClick={() => setOpen(false)}>
                  <p className=" font-montserrat text-left px-2 py-2 hover:bg-slate-400">
                    Home
                  </p>
                </Link>
                <Link to="/about-us" onClick={() => setOpen(false)}>
                  <p className=" font-montserrat text-left px-2 py-2 hover:bg-slate-400">
                    About Us
                  </p>
                </Link>
                <Link to="/product" onClick={() => setOpen(false)}>
                  <p className=" font-montserrat text-left px-2 py-2 hover:bg-slate-400">
                    Products
                  </p>
                </Link>
                <Link to="/hubungi-kami" onClick={() => setOpen(false)}>
                  <p className=" font-montserrat text-left px-2 py-2 hover:bg-slate-400">
                    Contact
                  </p>
                </Link>
              </div>
            )}
          </div>
        </section>
      </div>
    </>
  );
};

export default Navbar;
