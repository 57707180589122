import React from "react";
import PlatHitam from "../../assets/plat_hitam1.jpeg";
import PlatHitamPhone from "../../assets/platHitamYa.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={PlatHitam} className="h-full w-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            PLAT HITAM
          </h1>
          <h2 className="text-xl font-helvetica text-primaryPandawa text-left mt-2 pl-1">
            {"[ Hot Rolled Plate - HRP ]"}
          </h2>
          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Dalam industri baja yang merujuk pada lembaran baja yang berwarna
            hitam yang memiliki karakteristik berbeda dengan baja dingin. Dimana
            Plat Hitam memiliki ketebalan yang bervariasi, memiliki sifat
            mekanis yang kuat dan tahan terhadap beban tinggi.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={PlatHitamPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            PLAT HITAM
          </h1>
          <h2 className="font-montserrat font-[700] text-sm text-primaryPandawa">
            {"[ Hot Rolled Plate - HRP ]"}
          </h2>
          <p className="font-montserrat text-justify mt-4">
            Dalam industri baja yang merujuk pada lembaran baja yang berwarna
            hitam yang memiliki karakteristik berbeda dengan baja dingin. Dimana
            Plat Hitam memiliki ketebalan yang bervariasi, memiliki sifat
            mekanis yang kuat dan tahan terhadap beban tinggi.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
