import React from "react";
import Background from "../../assets/platAbuVertical.jpg";
import FotoHeader from "../../assets/plat_abu3.jpeg";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">
                1. Industri Konstruksi
              </h2>
              <p className="text-justify mt-2">
                Plat abu-abu sering digunakan dalam konstruksi sebagai bahan
                struktural untuk pembuatan rangka bangunan, dinding, dan atap.
                Kelebihan kekuatan dan ketahanan korosi membuatnya pilihan yang
                populer.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Industri Otomotif
              </h2>
              <p className="text-justify mt-2">
                Plat abu-abu dapat digunakan dalam pembuatan kendaraan, termasuk
                mobil, kereta, dan pesawat. Kekuatan dan kekokohan plat besi
                membuatnya cocok untuk aplikasi transportasi.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Pembuatan Peralatan Berat
              </h2>
              <p className="text-justify mt-2">
                Plat abu-abu sering digunakan dalam pembuatan peralatan berat
                seperti truk, bulldozer, dan alat konstruksi lainnya. Ketahanan
                terhadap tekanan dan beban berat membuatnya cocok untuk
                digunakan dalam kondisi kerja yang keras.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                4. Pembuatan Peralatan Rumah Tangga
              </h2>
              <p className="text-justify mt-2">
                Plat abu-abu digunakan dalam pembuatan alat-alat rumah tangga
                seperti peralatan dapur. Plat abu dapat digunakan dalam proses
                pembuatan panci, wajan, atau rak penyimpanan.{" "}
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Industri Konstruksi
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat abu-abu sering digunakan dalam konstruksi sebagai bahan
                struktural untuk pembuatan rangka bangunan, dinding, dan atap.
                Kelebihan kekuatan dan ketahanan korosi membuatnya pilihan yang
                populer.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Industri Otomotif
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat abu-abu dapat digunakan dalam pembuatan kendaraan, termasuk
                mobil, kereta, dan pesawat. Kekuatan dan kekokohan plat besi
                membuatnya cocok untuk aplikasi transportasi.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Pembuatan Peralatan Berat
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat abu-abu sering digunakan dalam pembuatan peralatan berat
                seperti truk, bulldozer, dan alat konstruksi lainnya. Ketahanan
                terhadap tekanan dan beban berat membuatnya cocok untuk
                digunakan dalam kondisi kerja yang keras.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                4. Pembuatan Peralatan Rumah Tangga
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat abu-abu digunakan dalam pembuatan alat-alat rumah tangga
                seperti peralatan dapur. Plat abu dapat digunakan dalam proses
                pembuatan panci, wajan, atau rak penyimpanan.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
