import React from "react";
import { Link } from "react-router-dom"
import PlatHitam from "../../assets/plat_hitam1.jpeg";
import PlatPutih from "../../assets/plat_putih2.jpeg";
import PlatAbu from "../../assets/plat_abu1.jpeg";
import PlatKapal from "../../assets/IMG_7303.PNG";

const Plat = () => {
  return (
    <>
      <section className="container-screen pt-8 sm:pt-12 py-5">
        <h1 className="text-left text-4xl tracking-wider text-[#252525] font-helvetica font-extrabold ">
          PLAT
        </h1>
      </section>
      <section className="bg-[#252525] py-4 sm:py-9">
        <div className="container-screen">
          <div className="grid grid-cols-4  gap-3 sm:gap-24 place-items-start">
            <Link to="/plat-hitam">
              <div>
                <div className="overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48 bg-white">
                  <img
                    src={PlatHitam}
                    className="hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-xs sm:text-lg mt-2">
                  Plat Hitam
                </p>
              </div>
            </Link>
            <Link to="/plat-putih">
              <div>
                <div className="bg-white overflow-hidden rounded-lg h-16 w-16 sm:w-48 sm:h-48">
                  <img
                    src={PlatPutih}
                    className="hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-xs sm:text-lg mt-2">
                  Plat Putih
                </p>
              </div>
            </Link>
            <Link to="/plat-abu">
              <div>
                <div className="bg-white overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48">
                  <img
                    src={PlatAbu}
                    className="h-full w-full hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-[10px] sm:text-lg mt-2">
                  Plat Abu-Abu
                </p>
              </div>
            </Link>
            <Link to="/plat-kapal">
              <div>
                <div className="bg-white overflow-hidden rounded-lg h-16 w-16  sm:w-48 sm:h-48">
                  <img
                    src={PlatKapal}
                    className="hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                  />
                </div>
                <p className="font-montserrat text-white text-[10px] sm:text-lg mt-2">
                  Plat Kapal
                </p>
              </div>
            </Link>
          </div>
        </div>
      </section>
    </>
  );
};

export default Plat;
