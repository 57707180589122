import React from "react";
import CoilHitam from "../../assets/coilHitamFull.jpg";
import CoilHitamPhone from "../../assets/coilHitamFullHeader.jpg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={CoilHitam} className="h-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            COIL HITAM
          </h1>
          <h2 className="text-xl font-helvetica text-primaryPandawa text-left mt-2 pl-1">
            {"[ Hot Rolled Coil ]"}
          </h2>
          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Coil hitam adalah produk baja yang menjadi tulang punggung dalam
            berbagai aplikasi konstruksi dan manufaktur. Dikembangkan melalui
            proses pembentukan dan penggulungan panas, coil hitam menawarkan
            kombinasi unggul antara kekuatan, keuletan, dan ketahanan terhadap
            korosi.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={CoilHitamPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            COIL HITAM
          </h1>
          <h2 className="font-montserrat font-[700] text-sm text-primaryPandawa">{"[ Hot Rolled Coil ]"}</h2>
          <p className="font-montserrat text-justify mt-4">
            Coil hitam adalah produk logam yang dikenal karena warnanya yang
            gelap atau hitam. Biasanya, coil hitam terbuat dari baja karbon, dan
            warna gelapnya adalah hasil dari proses perlakuan permukaan atau
            pengecatan khusus yang melibatkan pelapisan dengan minyak, lilin,
            atau zat kimia lainnya.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
