import React from "react";
import Background from "../../assets/steelManufacturingCoilPutih.jpg";
import FotoHeader from "../../assets/CoilPutihProduct.jpeg";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">1. Industri Otomotif</h2>
              <p className="text-justify mt-2">
                Coil putih memiliki peran penting dalam industri otomotif
                sebagai bahan utama untuk pembuatan bodi kendaraan. Kelebihannya
                yang utama terletak pada kemampuannya untuk memberikan permukaan
                yang bersih, halus, dan tahan terhadap korosi.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Komponen Elektronik
              </h2>
              <p className="text-justify mt-2">
                Sifatnya yang non-magnetik dan konduktif membuatnya cocok untuk
                digunakan dalam lingkungan elektronik yang sensitif, sementara
                permukaannya yang bersih memberikan penampilan yang profesional.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Peralatan Rumah Tangga
              </h2>
              <p className="text-justify mt-2">
                Coil putih dapat digunakan untuk membuat peralatan rumah tangga
                seperti kulkas, oven, dan mesin cuci. Permukaannya yang halus
                dan tahan terhadap noda membuatnya ideal untuk produk-produk
                konsumen yang memerlukan kebersihan dan estetika. Selain itu,
                ketahanannya terhadap korosi juga menjadikannya pilihan yang
                tangguh untuk lingkungan rumah tangga.
              </p>
              
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Industri Otomotif
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil putih memiliki peran penting dalam industri otomotif
                sebagai bahan utama untuk pembuatan bodi kendaraan. Kelebihannya
                yang utama terletak pada kemampuannya untuk memberikan permukaan
                yang bersih, halus, dan tahan terhadap korosi.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Komponen Elektronik
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Sifatnya yang non-magnetik dan konduktif membuatnya cocok untuk
                digunakan dalam lingkungan elektronik yang sensitif, sementara
                permukaannya yang bersih memberikan penampilan yang profesional.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Peralatan Rumah Tangga
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil putih dapat digunakan untuk membuat peralatan rumah tangga
                seperti kulkas, oven, dan mesin cuci. Permukaannya yang halus
                dan tahan terhadap noda membuatnya ideal untuk produk-produk
                konsumen yang memerlukan kebersihan dan estetika. Selain itu,
                ketahanannya terhadap korosi juga menjadikannya pilihan yang
                tangguh untuk lingkungan rumah tangga.
              </p>
             
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
