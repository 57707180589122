import React from "react";
import Background from "../../assets/headerGalvanisPhone.jpg";
import FotoHeader from "../../assets/coil_galvanil3.jpeg";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">
                1. Industri Konstruksi
              </h2>
              <p className="text-justify mt-2">
                Coil galvanis banyak digunakan dalam konstruksi bangunan sebagai
                bahan untuk atap, rangka, dan elemen struktural lainnya. Lapisan
                seng yang melapisi baja membantu melindungi dari korosi,
                menjadikannya pilihan yang tahan lama untuk lingkungan
                konstruksi yang berat.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Peralatan Listrik dan Elektronik
              </h2>
              <p className="text-justify mt-2">
                Coil galvanis digunakan dalam pembuatan peralatan listrik dan
                elektronik. Sifat konduktifnya dan perlindungan terhadap korosi
                membuatnya ideal untuk digunakan dalam berbagai komponen
                elektronik, seperti panel kontrol dan peralatan kelistrikan.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Industri Otomotif
              </h2>
              <p className="text-justify mt-2">
                Dalam industri otomotif, coil galvanis digunakan untuk membuat
                panel bodi, rangka kendaraan, dan komponen lainnya. Lapisan
                galvanis memberikan perlindungan tambahan terhadap korosi, yang
                krusial untuk menjaga integritas struktural kendaraan.
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Industri Konstruksi
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil galvanis banyak digunakan dalam konstruksi bangunan sebagai
                bahan untuk atap, rangka, dan elemen struktural lainnya. Lapisan
                seng yang melapisi baja membantu melindungi dari korosi,
                menjadikannya pilihan yang tahan lama untuk lingkungan
                konstruksi yang berat.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Peralatan Listrik dan Elektronik
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Coil galvanis digunakan dalam pembuatan peralatan listrik dan
                elektronik. Sifat konduktifnya dan perlindungan terhadap korosi
                membuatnya ideal untuk digunakan dalam berbagai komponen
                elektronik, seperti panel kontrol dan peralatan kelistrikan.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Industri Otomotif
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Dalam industri otomotif, coil galvanis digunakan untuk membuat
                panel bodi, rangka kendaraan, dan komponen lainnya. Lapisan
                galvanis memberikan perlindungan tambahan terhadap korosi, yang
                krusial untuk menjaga integritas struktural kendaraan.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
