import React from "react";
import { Navbar, Footer } from "../components";
import BgCoilHitam from "../assets/coilHitam.PNG";
import AboutImage from "../assets/steelManufacturing.PNG";
import CoilHitam from "../assets/coilHitamFull.jpg";
import CoilPutih from "../assets/CoilPutihProduct.jpeg";
import CoilAbu from "../assets/coilAbuProducts.jpeg";
import CoilGalvanis from "../assets/coilGalvanisProducts.jpeg";
import CoilGalvanil from "../assets/coil_galvanil2.jpg"
import PlatHitam from "../assets/plat_hitam1.jpeg";
import PlatPutih from "../assets/plat_putih2.jpeg";
import PlatAbu from "../assets/plat_abu1.jpeg";
import PlatKapal from "../assets/IMG_7303.PNG";
import BgPlat from "../assets/IMG_7303.PNG";
import Lokasi from "../assets/lokasi.jpg";
import { Link } from "react-router-dom";

const Home = () => {
  const sectionStyle = {
    backgroundImage: `url(${BgCoilHitam})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };

  const platStyle = {
    backgroundImage: `url(${BgPlat})`,
    backgroundSize: "cover",
    backgroundPosition: "0% 60%",
    overflow: "hidden",
  };

  const coilStyle = {};
  return (
    <>
      <Navbar />
      <div className="flex flex-col">
        <section className=" w-full  overflow-hidden" style={sectionStyle}>
          <div className=" w-full h-[500px] bg-gradient-to-r from-black/80 to-black/80 sm:bg-gradient-to-r sm:from-black flex items-center  text-center sm:text-left bg-opacity-20 ">
            <div className="container-screen ">
              <div className="sm:w-1/2 2-full mb-5">
                <p className="font-impact text-white text-5xl sm:text-7xl leading-snug tracking-wide">
                  Indonesia Steel <br />
                  <span>Distributor</span>
                </p>
              </div>

              <div className="sm:w-1/2 w-full mb-5">
                <p className="montserrat-text text-center sm:text-left">
                  PT Pandawa Jaya Steel perusahaan besi yang unggul dan handal.
                  Menyediakan barang yang bermutu dan berkualitas tinggi dengan
                  harga yang kompetitif.
                </p>
              </div>

              <div>
                <Link to="/hubungi-kami">
                  <button className="button-primary mr-2 sm:mr-5">
                    <p>Contact us</p>
                  </button>
                </Link>
                <Link to="/product">
                  <button className="button-outline">
                    <p>Our Products</p>
                  </button>
                </Link>
              </div>
            </div>
          </div>
        </section>

        <section className="py-16">
          <div className="container-screen">
            <div className="grid grid-cols-1 sm:grid-cols-2 items-center justify-center">
              <div className=" h-[100px] sm:h-[300px] rounded-xl w-full sm:w-11/12 overflow-hidden">
                <img
                  className="h-[200px] sm:h-[300px] object-fill"
                  src={AboutImage}
                  alt="steelManufacturing.PNG"
                />
              </div>
              <div className="text-justify">
                <p className="font-helvetica  text-primaryPandawa text-2xl mb-5 mt-5 sm:mt-0">
                  ABOUT US
                </p>
                <p className="font-montserrat text-xs sm:text-base">
                  PT Pandawa Jaya Steel adalah perusahaan yang berdiri sejak
                  tahun 1999 di Indonesia. PT Pandawa Jaya Steel hadir di
                  Indonesia sebagai perusahaan besi yang datang dengan penuh
                  keyakinan dan kepercayaan diri agar bisa masuk kek dalam
                  perdagangan besi di Indonesia.
                </p>
              </div>
            </div>
          </div>
        </section>
        {/* PRODUK COIL PC*/}
        <section className="sm:block hidden">
          <div style={sectionStyle}>
            <div className="bg-primaryPandawa/90 pb-12">
              <h1 className="font-helvetica text-xl bg-black/80 py-5 text-white mb-10">
                OUR PRODUTCS
              </h1>
              <div className="flex flex-col container-screen items-center">
                <div className="w-3/12 mb-5">
                  <h1 className="font-helvetica tracking-widest text-white text-5xl">
                    COIL
                  </h1>
                </div>
                <div className="grid grid-cols-3 w-full justify-items-center">
                  <Link to="/coil-hitam">
                    <div className="flex justify-start items-center mb-3">
                      <div className="h-[170px] w-[170px] rounded-xl overflow-hidden bg-white">
                        <img
                          className="w-[180px] h-full hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                          src={CoilHitam}
                          alt="coilHitam.jpg"
                        />
                      </div>
                      <p className="product-text  border-l-4 border-white pl-4 ml-7  text-left text-xl">
                        Coil <br />
                        Hitam
                      </p>
                    </div>
                  </Link>
                  <Link to="/coil-putih">
                    <div className="flex justify-start items-center ">
                      <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                        <img
                          className="w-[180px] h-full hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                          src={CoilPutih}
                          alt="coilPutih.jpg"
                        />
                      </div>
                      <p className="product-text border-l-4 border-white pl-4 ml-7  text-left text-xl ">
                        Coil <br />
                        Putih
                      </p>
                    </div>
                  </Link>
                  <Link to="/coil-abu">
                    <div className="flex justify-start items-center">
                      <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                        <img
                          className="w-[180px] hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                          src={CoilAbu}
                          alt="coilAbu.jpg"
                        />
                      </div>
                      <p className="text-base  product-text border-l-4 border-white pl-4 ml-7  text-left ">
                        Coil <br />
                        Abu - Abu
                      </p>
                    </div>
                  </Link>
                </div>
                <div className="grid grid-cols-2 w-3/4 justify-items-center">
                  <Link to="/coil-galvanis">
                    <div className="flex justify-start items-center mt-8">
                      <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                        <img
                          className="w-[180px] hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                          src={CoilGalvanis}
                          alt="coilGalvanis.jpg"
                        />
                      </div>
                      <p className="text-base product-text  border-l-4  border-white pl-4 ml-7   text-left ">
                        Coil <br />
                        Galvanis
                      </p>
                    </div>
                  </Link>
                  <Link to="/coil-galvanil">
                    <div className="flex justify-start items-center mt-8">
                      <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                        <img
                          className="scale-110 hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                          src={CoilGalvanil}
                          alt="coilGalvanil.jpg"
                        />
                      </div>
                      <p className="text-base product-text  border-l-4  border-white pl-4 ml-7  text-left ">
                        Coil <br />
                        Galvanil
                      </p>
                    </div>
                  </Link>                
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* PRODUK COIL PHONE*/}
        <section className="sm:hidden block" style={sectionStyle}>
          <section className="bg-primaryPandawa/90 py-8">
            <div className="container-screen">
              <div className="font-helvetica font-bold text-white flex justify-end items-center">
                <h1 className="border-r-4 px-3 border-white">OUR PRODUCTS</h1>
              </div>
              <div className="font-montserrat font-bold text-white mt-6 flex justify-start items-center">
                <h1 className="tracking-widest">COIL</h1>
              </div>
              <div className="grid grid-cols-4 mt-3 justify-items-start">
                <Link to="/coil-hitam">
                  <div>
                    <div className="rounded-lg overflow-hidden w-[70px] h-[70px]">
                      <img src={CoilHitam} alt="coilHitam.png" />
                    </div>
                    <p className="font-montserrat text-white text-xs mt-3">
                      Coil Hitam
                    </p>
                  </div>
                </Link>
                <Link to="/coil-putih">
                  <div>
                    <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                      <img
                        src={CoilPutih}
                        alt="coilHitam.png"
                        className="w-[100px] h-[70px]"
                      />
                    </div>
                    <p className="font-montserrat text-white text-xs mt-3">
                      Coil Putih
                    </p>
                  </div>
                </Link>
                <Link to="/coil-abu">
                  <div>
                    <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                      <img
                        src={CoilAbu}
                        alt="coilHitam.png"
                        className="w-[100px] h-[70px]"
                      />
                    </div>
                    <p className="font-montserrat text-white text-xs mt-3">
                      Coil <br />
                      Abu-Abu
                    </p>
                  </div>
                </Link>
                <Link to="/coil-galvanis">
                  <div>
                    <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                      <img
                        src={CoilGalvanis}
                        alt="coilHitam.png"
                        className="w-[100px] h-[70px]"
                      />
                    </div>
                    <p className="font-montserrat text-white text-xs mt-3">
                      Coil <br />
                      Galvanis
                    </p>
                  </div>
                </Link>
                <Link to="/coil-galvaniL">
                  <div>
                    <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                      <img
                        src={CoilGalvanil}
                        alt="coilGalvanil.png"
                        className="w-[100px] h-[70px]"
                      />
                    </div>
                    <p className="font-montserrat text-white text-xs mt-3">
                      Coil <br />
                      Galvanil
                    </p>
                  </div>
                </Link>
              </div>
            </div>
          </section>
        </section>

        {/* PLAT PC */}
        <section className="sm:block hidden" style={platStyle}>
          <section className="bg-black/80 py-12">
            <div className="container-screen flex items-center">
              <div className="grid grid-cols-2 w-7/12">
                <Link to="/plat-hitam">
                  <div className="flex items-center justify-start">
                    <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                      <img
                        src={PlatHitam}
                        alt="platHitam.jpg"
                        className="object-right-bottom hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                      />
                    </div>
                    <div>
                      <p className="border-l-4 border-white text-white font-montserrat product-text ml-7 pl-3 text-left">
                        Plat <br /> Hitam
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="/plat-putih">
                  <div className="flex items-center justify-start ml-5">
                    <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                      <img
                        src={PlatPutih}
                        alt="platHitam.jpg"
                        className="object-right-bottom hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                      />
                    </div>
                    <div>
                      <p className="border-l-4 border-white text-white font-montserrat product-text ml-7 pl-3 text-left">
                        Plat <br /> Putih
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="/plat-abu">
                  <div className="flex items-center justify-start mt-8">
                    <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                      <img
                        src={PlatAbu}
                        alt="platHitam.jpg"
                        className="h-full object-right-bottom hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                      />
                    </div>
                    <div>
                      <p className="border-l-4 border-white text-white font-montserrat product-text ml-7 pl-3 text-left">
                        Plat <br /> Abu - Abu
                      </p>
                    </div>
                  </div>
                </Link>
                <Link to="/plat-kapal">
                  <div className="flex items-center justify-start mt-8 ml-5">
                    <div className="h-[170px] w-[170px] rounded-xl bg-white overflow-hidden">
                      <img
                        src={PlatKapal}
                        alt="platHitam.jpg"
                        className="object-right-bottom hover:scale-125 transition duration hover:cursor-pointer active:opacity-60"
                      />
                    </div>
                    <div>
                      <p className="border-l-4 border-white text-white font-montserrat product-text ml-7 pl-3 text-left">
                        Plat <br /> Kapal
                      </p>
                    </div>
                  </div>
                </Link>
              </div>
              <div className="ml-auto w-5/12">
                <h1 className="font-helvetica tracking-widest text-white text-5xl">
                  PLAT
                </h1>
              </div>
            </div>
          </section>
        </section>
      </div>

      {/* PLAT MOBILE */}
      <section className="sm:hidden block" style={platStyle}>
        <section className="bg-black/80 py-8">
          <div className="container-screen">
            <div className="font-montserrat font-bold text-white mt-6 flex justify-start items-center">
              <h1 className="tracking-widest">PLAT</h1>
            </div>
            <div className="grid grid-cols-4 mt-3 justify-items-start">
              <Link to="/plat-hitam">
                <div>
                  <div className="rounded-lg overflow-hidden w-[70px] h-[70px]">
                    <img src={PlatHitam} alt="coilHitam.png" />
                  </div>
                  <p className="font-montserrat text-white text-xs mt-3">
                    Plat Hitam
                  </p>
                </div>
              </Link>
              <Link to="/plat-putih">
                <div>
                  <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                    <img
                      src={PlatPutih}
                      alt="coilHitam.png"
                      className="w-[100px] h-[70px]"
                    />
                  </div>
                  <p className="font-montserrat text-white text-xs mt-3">
                    Plat Putih
                  </p>
                </div>
              </Link>
              <Link to="/plat-abu">
                <div>
                  <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                    <img
                      src={PlatAbu}
                      alt="coilHitam.png"
                      className="w-[100px] h-[70px]"
                    />
                  </div>
                  <p className="font-montserrat text-white text-xs mt-3">
                    Plat <br />
                    Abu-Abu
                  </p>
                </div>
              </Link>
              <Link to="/plat-kapal">
                <div>
                  <div className="rounded-lg overflow-hidden w-[70px] h-[70px] bg-white">
                    <img
                      src={PlatKapal}
                      alt="coilHitam.png"
                      className="w-[100px] h-[70px]"
                    />
                  </div>
                  <p className="font-montserrat text-white text-xs mt-3">
                    Plat <br />
                    Kapal
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </section>
      </section>

      {/* FIND US HERE PC */}

      <section className="py-20 hidden sm:block">
        <div className="container-screen grid grid-cols-2 place-items-center">
          <div className="rounded-md overflow-hidden shadow-2xl">
            <img src={Lokasi} />
          </div>
          <div className="justify-self-start pl-28 flex flex-col items-start">
            <p className="font-helvetica font-bold text-3xl text-left mb-5">
              FIND US HERE
            </p>
            <p className="font-montserrat text-xl text-left mb-5">
              Temukan kantor dan gudang <br />
              kami di sini
            </p>
            <Link to="https://www.google.com/maps/search/pt+pandawa+jaya+steel/@-6.1608567,106.4752356,11z/data=!3m1!4b1?entry=ttu">
              <button className="bg-primaryPandawa font-semibold  text-white px-16 mt-3 py-4 rounded-full hover:bg-primaryPandawaHover transition duration-150">
                Temukan Kami
              </button>
            </Link>
          </div>
        </div>
      </section>

      {/* FIND US HERE PHONE */}
      <section className="container-screen py-8 sm:hidden">
        <div>
          <p className="font-helvetica font-bold text-start text-primaryPandawa pl-3 border-l-4 border-primaryPandawa">
            FIND US HERE
          </p>
          <p className="font-montserrat text-left font-semibold text-xs mt-3">
            Temukan gudang dan kantor <br />
            kami di sini
          </p>
        </div>
        <div className="grid grid-cols-3 mt-8 justify-items-start ">
          <Link to="/https://www.google.com/maps/search/pt+pandawa+jaya+steel/@-6.1608567,106.4752356,11z/data=!3m1!4b1?entry=ttu">
            <button className="bg-primaryPandawa font-semibold justify-self-center text-white px-3  py-2 text-xs rounded-full hover:bg-primaryPandawaHover transition duration-150">
              Temukan Kami
            </button>
          </Link>
          <img src={Lokasi} className="col-span-2" />
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Home;
