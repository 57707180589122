import React from "react";
import {
  PhoneIcon,
  EnvelopeIcon,
  MapPinIcon,
} from "@heroicons/react/24/outline";
import Instagram from "../assets/ig.png";
import LinkedIn from "../assets/linkedin.png";
import WhatsApp from "../assets/logo_wa.png";
import { Link } from "react-router-dom";

const FooterOther = () => {
  return (
    <section className="block">
      <section className="bg-black/90 py-2">
        <div className="container-screen">
          <h1 className="text-start font-helvetica text-white text-xs sm:text-xl mb-2 sm:mb-4">
            OUR CONTACT
          </h1>
          <div className="grid grid-cols-3">
            <div className="col-span-2 sm:col-span-1 justify-items-start">
              <div className="flex items-center sm:my-1">
                <EnvelopeIcon className="sm:h-5 sm:w-5 h-3 w-3 text-white my-1" />
                <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs text-left tracking-wide">
                  pandawajayasejahtera52@gmail.com
                </p>
              </div>
              <div className="flex items-center sm:my-1">
                <PhoneIcon className="sm:h-5 sm:w-5 h-3 w-3 text-white my-1" />
                <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                  (021) 5931-0052
                </p>
              </div>
              <div className="flex items-center sm:my-1">
                <img
                  src={WhatsApp}
                  className="sm:h-5 sm:w-5 h-3 w3 text-white my-1"
                />
                <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs text-left tracking-wide">
                  +62 878-8236-0070 / +62 878-8155-1652
                </p>
              </div>
            </div>
            <div className="hidden sm:flex col-span-1  justify-items-start items-start ml-3">
              <div className="flex items-center">
                <MapPinIcon className="sm:h-5 sm:w-5 h-3 w-3 text-white my-1 ml-auto" />
              </div>
              <p className="mt-1 font-montserrat ml-3 text-white text-[5px] sm:text-xs text-left tracking-wide">
                Jl. Industri Raya III, Pasir Jaya, Kec. Cikupa, Kabupaten
                Tangerang, Banten 15710
              </p>
            </div>
            <div className="col-span-1 ml-auto">
              
              <Link to="https://www.instagram.com/pt.pandawajayasejahtera/?utm_source=ig_web_button_share_sheet&igshid=OGQ5ZDc2ODk2ZA==">
                <div className="flex items-center sm:my-1">
                  <img
                    src={Instagram}
                    className="sm:h-5 sm:w-5 h-3 w3 text-white my-1 "
                  />
                  <p className="font-montserrat text-white ml-3 text-[5px] sm:text-xs tracking-wide">
                    @pt.pandawajayasejahtera
                  </p>
                </div>
              </Link>
              <div className="sm:hidden flex">
                <div className="flex items-center">
                  <MapPinIcon className="sm:h-5 sm:w-5 h-3 w-3 text-white my-1 ml-auto" />
                </div>
                <p className="mt-1 font-montserrat ml-3 text-white text-[5px] sm:text-xs text-left tracking-wide">
                  Jl. Industri Raya III, Pasir Jaya, Kec. Cikupa, Kabupaten
                  Tangerang, Banten 15710
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </section>
  );
};

export default FooterOther;
