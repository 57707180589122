import React from "react";
import Background from "../../assets/platHitamPhone.jpeg";
import FotoHeader from "../../assets/plat_hitam1.jpeg";

const Aplikasi = () => {
  const BgHeader = {
    backgroundImage: `url(${FotoHeader})`,
    backgroundSize: "cover",
    backgroundPosition: "40% 60%",
    overflow: "hidden",
  };
  return (
    <>
      {/* APLIKASI PC */}
      <section className="hidden sm:block">
        <h1 className="text-4xl container-screen text-primaryPandawa font-helvetica text-left">
          Aplikasi
        </h1>
        <section className="container-screen grid grid-cols-5 py-10">
          <div className="col-span-3">
            <div>
              <h2 className="text-left font-helvetica">1. Pembuatan Kapal</h2>
              <p className="text-justify mt-2">
                Dalam industri pembuatan kapal, plat hitam digunakan untuk
                pembuatan lambung kapal, dek, dan struktur utama lainnya.
                Ketahanan terhadap korosi dan kekuatannya menjadikannya bahan
                yang sangat diandalkan untuk kapal yang terpapar lingkungan
                laut.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                2. Pembuatan Container
              </h2>
              <p className="text-justify mt-2">
                Plat hitam dapat digunakan untuk membuat tangki penyimpanan dan
                silo dalam industri kimia atau pertanian. Keuletan dan
                kekokohannya memastikan keamanan dan keandalan dalam menyimpan
                berbagai bahan cair atau padat.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                3. Pembuatan Peralatan Berat
              </h2>
              <p className="text-justify mt-2">
                Plat hitam sering digunakan dalam pembuatan peralatan berat
                seperti truk, bulldozer, dan alat konstruksi lainnya. Ketahanan
                terhadap tekanan dan beban berat membuatnya cocok untuk
                digunakan dalam kondisi kerja yang keras.
              </p>
              <h2 className="text-left font-helvetica mt-12">
                4. Konstruksi Bangunan
              </h2>
              <p className="text-justify mt-2">
                Plat hitam digunakan dalam konstruksi bangunan sebagai bahan
                struktural untuk pembuatan kolom, balok, dan struktur utama
                lainnya. Keunggulan plat hitam terletak pada kekuatannya yang
                tinggi, memberikan dukungan yang kokoh dan tahan lama untuk
                bangunan.{" "}
              </p>
            </div>
          </div>
          <div className="col-span-2  rounded-lg overflow-hidden ml-auto  h-full w-1/2">
            <img src={Background} className="h-full" />
          </div>
        </section>
      </section>
      {/* PLAT PC */}
      <section className="sm:hidden" style={BgHeader}>
        <div className="bg-primaryPandawa/90 py-8">
          <div className="container-screen">
            <h1 className="font-helvetica  text-white tracking-widest text-left text-2xl mb-10">
              APLIKASI
            </h1>
            <div className="text-left text-white">
              <h2 className="font-helvetica tracking-wider">
                1. Pembuatan Kapal
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Dalam industri pembuatan kapal, plat hitam digunakan untuk
                pembuatan lambung kapal, dek, dan struktur utama lainnya.
                Ketahanan terhadap korosi dan kekuatannya menjadikannya bahan
                yang sangat diandalkan untuk kapal yang terpapar lingkungan
                laut.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                2. Pembuatan Container
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat hitam dapat digunakan untuk membuat tangki penyimpanan dan
                silo dalam industri kimia atau pertanian. Keuletan dan
                kekokohannya memastikan keamanan dan keandalan dalam menyimpan
                berbagai bahan cair atau padat.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                3. Pembuatan Peralatan Berat
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat hitam sering digunakan dalam pembuatan peralatan berat
                seperti truk, bulldozer, dan alat konstruksi lainnya. Ketahanan
                terhadap tekanan dan beban berat membuatnya cocok untuk
                digunakan dalam kondisi kerja yang keras.
              </p>
              <h2 className="font-helvetica tracking-wider mt-12">
                4. Konstruksi Bangunan
              </h2>
              <p className="font-montserrat text-justify mt-2">
                Plat hitam digunakan dalam konstruksi bangunan sebagai bahan
                struktural untuk pembuatan kolom, balok, dan struktur utama
                lainnya. Keunggulan plat hitam terletak pada kekuatannya yang
                tinggi, memberikan dukungan yang kokoh dan tahan lama untuk
                bangunan.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Aplikasi;
