import React from "react";
import CoilPutih from "../../assets/CoilPutihProduct.jpeg";
import CoilPutihPhone from "../../assets/coilPutihPhone.jpeg";

const Header = () => {
  return (
    <>
      {/* PC FORMAT */}
      <section className="container-screen sm:grid grid-cols-5 py-12 hidden">
        <div className="h-[350px] w-[350px] overflow-hidden rounded-lg col-span-2">
          <img src={CoilPutih} className="h-full" />
        </div>
        <div className="flex flex-col justify-center col-span-3">
          <h1 className="text-4xl font-helvetica text-primaryPandawa text-left tracking-wide">
            COIL PUTIH
          </h1>
          <h2 className="text-xl font-helvetica text-primaryPandawa text-left mt-2 pl-1">
            {"[ Cold Rolled Coil ]"}
          </h2>
          <p className="pl-1 text-justify font-montserrat text-lg mt-4">
            Coil Putih adalah produk baja yang terkenal dengan sebutan baja
            gulungan putih dan diproduksi dengan proses coil rolling. Baja
            gulungan Putih memiliki permukaan akhir yang tinggi dan toleransi
            ketebalan yang rapat serta sifat ketangguhan yang umumnya baik.
          </p>
        </div>
      </section>

      {/* MOBILE FORMAT */}
      <section className="sm:hidden">
        <div className="h-24 overflow-hidden">
          <img src={CoilPutihPhone} />
        </div>
        <div className="container-screen py-10">
          <h1 className="font-helvetica text-2xl text-primaryPandawa tracking-widest">
            COIL PUTIH
          </h1>
          <h2 className="font-montserrat font-[700] text-sm text-primaryPandawa">
            {"[ Hot Rolled Coil ]"}
          </h2>
          <p className="font-montserrat text-justify mt-4">
            Coil Putih adalah produk baja yang terkenal dengan sebutan baja
            gulungan putih dan diproduksi dengan proses coil rolling. Baja
            gulungan Putih memiliki permukaan akhir yang tinggi dan toleransi
            ketebalan yang rapat serta sifat ketangguhan yang umumnya baik.
          </p>
        </div>
      </section>
    </>
  );
};

export default Header;
