import React from "react";
import { Navbar, Footer } from "../components";
import { Header, Aplikasi} from "../components/CoilPutihPage.jsx"

const CoilPutih = () => {
  return (
    <>
      <Navbar />
      <section>
        <Header />
      </section>
      <section className="pt-10">
        <Aplikasi />
      </section>
      <Footer />
    </>
  );
};

export default CoilPutih;
